import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoginResponse, IregisterResponse } from '../interfaces/login-response.interface';
import { LoginRequest, IregisterRequest } from '../interfaces/login-request.interface';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private url: string = environment.urlTeso;
  private apiKey: string = environment.apiKey;

  constructor(
    private http: HttpClient
  ) { }

  register(data: IregisterRequest) {
    const scope = `${this.url}/auth/register`;
    const headers: HttpHeaders = new HttpHeaders({ 'x-api-key': this.apiKey });

    return this.http.post<IregisterResponse>(`${scope}`, data, { headers });
  }

  login(data: LoginRequest) {
    const scope = `${this.url}/auth/login`;
    const headers: HttpHeaders = new HttpHeaders({ 'x-api-key': this.apiKey });
    return this.http.post<LoginResponse>(`${scope}`, data, { headers });
  }

  decodeToken() {
    const helper = new JwtHelperService();
    const token = localStorage.getItem('token') ?? '';
    return helper.decodeToken(token);
  }

  isExpiredToken() {
    const helper = new JwtHelperService();
    const token = localStorage.getItem('token') ?? '';
    return helper.isTokenExpired(token);
  }

}
