import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CryptoService } from 'src/app/private/shared/services/crypto.service';
import { AuthService } from 'src/app/public/auth/shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RolGuard implements CanActivate {

  constructor(
    private aService: AuthService,
    private cService: CryptoService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let token = localStorage.getItem('token');
    if(token) {
      let aux = JSON.parse(this.cService.decrypt(this.aService.decodeToken().data));
      switch(aux.cat_rols_id) {
        case 2:
          return true;
          break;
        default:
          localStorage.clear();
          break;
      }
    } else {
      this.router.navigateByUrl('/public/auth/login');
    }
    return false;
  }
  
}
