// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  urlTeso: 'https://notarios-api.zurco.com.mx/api/v2/notarios',
  apiKey: 'fxih0ee6a8e9fhNPd1d2407d0',
  cryptoKey: "jc9ab428ad36458d2255dbda3dbe593fb3f6b32be921cb37c9db53f02cd2aa",
  firebase: {
    projectId: 'colegio-notarios-12667',
    appId: '1:525374417447:web:01c649b13324f2b9ccfc02',
    storageBucket: 'colegio-notarios-12667.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyBdKfuzSAPn9I4yvjftQswnYjrvlCd8BIk',
    authDomain: 'colegio-notarios-12667.firebaseapp.com',
    messagingSenderId: '525374417447',
    measurementId: 'G-V6C41EHJ21',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
