import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AES, enc } from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  constructor() { }

  private ck: string = environment.cryptoKey;

  encrypt(msg: string): string {
    return AES.encrypt(msg, this.ck).toString();
  }

  decrypt(code: string): string {  
    let bytes = AES.decrypt(code, this.ck);    
    return bytes.toString(enc.Utf8);
  }

  verifyCertKey() {
  }
}
